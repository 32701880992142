import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/Layout";
import { GameDescription } from "../../components/sections/games/GameDescription";
import { Container } from "../../components/layout/Container";
import { Navigation } from "../../components/navigation/Navigation";
import { HeaderSize } from "../../components/layout/ParallaxHeader";
import { ImageHeader } from "../../components/layout/ImageHeader";
import Img, { FluidObject } from "gatsby-image";
import { GalleryOrientation, GameGallery } from "../../components/sections/games/GameGallery";
import { ContainerColour } from "../../components/layout/DiagonalContainer";
import { Button, ButtonColour, ButtonSize } from "../../components/layout/Button";
import { recipeRescueFruitBlastPageData } from "../../content/pageData/games/RecipeRescueFruitBlastPageData";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        rrHeaderMobile: {
            childImageSharp: any;
        }
        rrHeaderXL: {
            childImageSharp: any;
        }
        rrHeader: {
            childImageSharp: any;
        }
        logo: {
            childImageSharp: any;
        }
        description: {
            childImageSharp: any;
        }
        one: {
            childImageSharp: any;
        }
        two: {
            childImageSharp: any;
        }
        three: {
            childImageSharp: any;
        }
        four: {
            childImageSharp: any;
        }
        watermelon: {
            childImageSharp: any;
        }
        grape: {
            childImageSharp: any;
        }
        orange: {
            childImageSharp: any;
        }
        apple: {
            childImageSharp: any;
        }
        blueberry: {
            childImageSharp: any;
        }
        banana: {
            childImageSharp: any;
        }
    }
}

export default ({ data }: PageData) => {
    const headerImageMobile = data.rrHeaderMobile.childImageSharp.fluid;
    const headerImageXL = data.rrHeaderXL.childImageSharp.fluid;
    const headerImage = data.rrHeader.childImageSharp.fluid;

    const headerSources = [
        headerImageMobile,
        {
            ...headerImageXL,
            media: `(min-width: 1100px)`,
        },
        {
            ...headerImage,
            media: `(min-width: 768px)`,
        }
    ]

    const images: FluidObject[] = [
        data.one.childImageSharp.fluid,
        data.two.childImageSharp.fluid,
        data.three.childImageSharp.fluid,
        data.four.childImageSharp.fluid,
    ];

    return (
        <Layout
            pageTitle={recipeRescueFruitBlastPageData.metaPageTitle}
            keywords={recipeRescueFruitBlastPageData.metaKeywords}
            description={recipeRescueFruitBlastPageData.metaDescription}
            url={"work/recipe-rescue-fruit-blast"}
        >
            <Navigation />
            <ImageHeader size={HeaderSize.LARGE} imageSrc={headerSources}>
                <div className="logo-container">
                    <div className="image-wrapper rrfb">
                        <Img fluid={data.logo.childImageSharp.fluid} alt={`${recipeRescueFruitBlastPageData.pageTitle} logo`} />
                    </div>
                </div>
            </ImageHeader>
            <Container className="game-description-container">
                <GameDescription
                    gameTitle={"Recipe Rescue: Fruit Blast"}
                    title={recipeRescueFruitBlastPageData.descriptionTitle}
                    description={recipeRescueFruitBlastPageData.description}
                    links={recipeRescueFruitBlastPageData.links}
                    image={data.description.childImageSharp.fluid}
                    imageAlt={"Nina Nutmeg"}
                />
            </Container>
            <GameGallery colour={ContainerColour.RRFB} images={images} orientation={GalleryOrientation.PORTRAIT}>
                <div className="rrfb-watermelon">
                    <Img fluid={data.watermelon.childImageSharp.fluid} alt="Red Fruit" />
                </div>
                <div className="rrfb-grape">
                    <Img fluid={data.grape.childImageSharp.fluid} alt="Purple Fruit" />
                </div>
                <div className="rrfb-orange">
                    <Img fluid={data.orange.childImageSharp.fluid} alt="Orange Fruit" />
                </div>
                <div className="rrfb-apple">
                    <Img fluid={data.apple.childImageSharp.fluid} alt="Green Fruit" />
                </div>
                <div className="rrfb-blueberry">
                    <Img fluid={data.blueberry.childImageSharp.fluid} alt="Blue Fruit" />
                </div>
                <div className="rrfb-banana">
                    <Img fluid={data.banana.childImageSharp.fluid} alt="Yellow Fruit" />
                </div>
            </GameGallery>
            <Container className="more-work">
                <Button buttonConfig={{
                    colour: ButtonColour.BLUE,
                    size: ButtonSize.LARGE,
                    ctaLabel: "More Work",
                    ctaLink: "/work",
                    id: "blueGradient",
                    ariaLabel: "View our other work",
                }}
                />
            </Container>
        </Layout>
    );
};


export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        rrHeaderMobile: file(relativePath: { eq: "assets/images/work/rrfb-header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 768, maxHeight: 550, quality: 100, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        rrHeaderXL: file(relativePath: { eq: "assets/images/work/rrfb-header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1581, maxHeight: 700, quality: 100, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        rrHeader: file(relativePath: { eq: "assets/images/work/rrfb-header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        logo: file(relativePath: { eq: "assets/images/work/rrfb/logo.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        description: file(relativePath: { eq: "assets/images/work/rrfb/description.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        one: file(relativePath: { eq: "assets/images/work/rrfb/1.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 864, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        two: file(relativePath: { eq: "assets/images/work/rrfb/2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 418, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        three: file(relativePath: { eq: "assets/images/work/rrfb/3.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 418, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        four: file(relativePath: { eq: "assets/images/work/rrfb/4.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 418, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        watermelon: file(relativePath: { eq: "assets/images/work/rrfb/watermelon.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        grape: file(relativePath: { eq: "assets/images/work/rrfb/grape.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        orange: file(relativePath: { eq: "assets/images/work/rrfb/orange.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        apple: file(relativePath: { eq: "assets/images/work/rrfb/apple.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        blueberry: file(relativePath: { eq: "assets/images/work/rrfb/blueberry.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        banana: file(relativePath: { eq: "assets/images/work/rrfb/banana.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`