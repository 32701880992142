import React from "react";
import Img, { FluidObject } from "gatsby-image";
import { ContainerColour, ContainerSize, DiagonalContainer, Direction } from "../../layout/DiagonalContainer";
import { useState } from "react";
import { useEffect } from "react";

interface Props {
    images: FluidObject[],
    colour: ContainerColour,
    orientation: GalleryOrientation,
    className?: string,
}

export enum GalleryOrientation {
    PORTRAIT = "portrait",
    LANDSCAPE = "landscape",
    SQUARE = "square",
}

export const GameGallery: React.FC<Props> = ({ children, images, colour, orientation, className = "" }) => {
    const [index, setIndex] = useState<number>(0);
    let timer: number;

    const renderImages = () => {
        const elements: JSX.Element[] = [];

        for (let i = 0; i < images.length; ++i) {
            const element = <div key={`gallery-image-${i}`} className={`image-container ${index !== i ? "fade-out" : ""}`}>
                <Img fluid={images[i]} alt={`gallery-image-${i + 1}`} />
            </div>
            elements.push(element);
        }

        return elements;
    }

    const renderControls = () => {
        const elements: JSX.Element[] = [];

        for (let i = 0; i < images.length; ++i) {
            const element = (
                <button
                    aria-label={`Jump to Gallery Image ${i + 1}`}
                    key={`gallery-button-${i}`}
                    className={`gallery-button ${index === i ? "active" : ""}`}
                    onClick={() => {
                        setIndex(i);
                        clearTimeout(timer);
                    }}
                >
                    <div className="accent" />
                </button>
            );
            elements.push(element);
        }

        return elements;
    }

    useEffect(() => {
        timer = window.setTimeout(() => {
            let i = index + 1;

            if (i >= images.length) {
                i = 0;
            }

            setIndex(i);
        }, 4000);
    });

    return (
        <div className={`game-gallery ${className} ${orientation}`}>
            <DiagonalContainer
                colour={colour}
                size={ContainerSize.LARGE}
                direction={Direction.RIGHT}
                hasRips={false}
            >
                <div className={`game-gallery-container ${orientation}`}>
                    {children}
                    <div className="phone-container">
                        <div className="speaker" />
                        <div className="filler" />
                        {renderImages()}
                        <div className="speaker" />
                        <div className="gallery-controls">
                            {renderControls()}
                        </div>
                    </div>
                </div>
            </DiagonalContainer>
        </div>
    )
}