import React from "react";
import { DiagonalCover } from "./DiagonalCover";
import { HeaderSize } from "./ParallaxHeader";
import { StraightCover } from "./StraightCover";
import { ArchivedIcon } from "./icons/ArchivedIcon";
import Img from "gatsby-image";

interface Props {
    size: HeaderSize,
    imageSrc: any,
    archived?: boolean,
    straightBottom?: boolean;
    className?: string,
}

export const ImageHeader: React.FC<Props> = ({ children, size, imageSrc, archived = false, straightBottom = false, className = "" }) => {
    return (
        <header className={`image-header ${size}-height ${className}`}>
            <div className="image-container">
                <Img fluid={imageSrc} />
            </div>
            {straightBottom ?
                <StraightCover fill={"#ffffff"} />
                :
                <DiagonalCover fill={"#ffffff"} />
            }
            {children}
            {archived &&
                <div className="archived">
                    <ArchivedIcon />
                </div>
            }
        </header>
    )
}