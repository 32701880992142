import React from "react";
import Img, { FluidObject } from "gatsby-image";
import { Button, ButtonConfig } from "../../layout/Button";
import { BlobOne } from "../../layout/blobs/BlobOne";
import { BlobThree } from "../../layout/blobs/BlobThree";

interface Props {
    gameTitle: string,
    title: string,
    description: JSX.Element,
    links: ButtonConfig[],
    image: FluidObject,
    imageAlt: string,
    className?: string,
}

export const GameDescription: React.FC<Props> = ({ gameTitle, title, description, links, image, imageAlt, className = "" }) => {

    const renderButtons = () => {
        const elements: JSX.Element[] = [];

        for (let i = 0; i < links.length; ++i) {
            const element = <Button key={`download-button-${i}`} buttonConfig={links[i]} />
            elements.push(element);
        }

        return elements;
    }

    return (
        <div className={`game-description ${className}`}>
            <div className="blob-container">
                <BlobOne fill={"#080a2e08"} />
                <BlobThree fill={"#080a2e08"} />
            </div>
            <div className="description">
                <h1 className="game-title">{gameTitle}</h1>
                <h2 className="description-title">{title}</h2>
                {description}
                {links.length > 0 &&
                    <div className="download-links">
                        <h2 className="download-title">Play on</h2>
                        <div className="download-buttons">
                            {renderButtons()}
                        </div>
                    </div>
                }
            </div>
            <div className="image-container">
                <Img fluid={image} alt={imageAlt} />
            </div>
        </div>
    )
}