import React from "react";

interface Props {
}

export const ArchivedIcon: React.FC<Props> = () => {
    return (
        <svg width="395" height="100" viewBox="0 0 395 100" fill={"#004eff"}>
            <path fill={"#ffffff"} d="M369.4,7.4c-2.1-4.6-5.9-7.4-10.1-7.4H214.5H36.9c0,0-0.1,0-0.1,0h-1.2c-4.2,0-8,2.8-10.1,7.4L0,96.3
            c0,2,1.3,3.6,2.9,3.6h66.3c0.1,0,0.3,0,0.4,0h144.9h177.6c1.6,0,2.9-1.6,2.9-3.6L369.4,7.4z"/>
            <path d="M131,63h-5l-1.7-5.4h-9.8l-1.6,5.4h-5l7.9-25.9h7.3L131,63z M119.3,41.6L115.9,53h7L119.3,41.6z" />
            <path d="M154.4,63h-5.5l-4.3-8.8h-5.5V63h-4.9V37h10.9c0.8,0,1.5,0,2.2,0.1c2.2,0.3,3.9,1.3,5,2.8
            c1.1,1.5,1.7,3.4,1.7,5.7c0,1.8-0.4,3.4-1.1,4.7c-0.7,1.3-1.8,2.3-3.2,3L154.4,63z M139.1,41.6v8h5.8c0.7,0,1.2-0.1,1.7-0.2
            c0.9-0.2,1.5-0.7,1.9-1.4c0.4-0.7,0.6-1.5,0.6-2.4s-0.2-1.6-0.6-2.4c-0.4-0.7-1-1.2-1.9-1.4c-0.4-0.1-1-0.2-1.7-0.2H139.1z"/>
            <path d="M161.7,61.8c-1.9-1.1-3.4-2.7-4.4-4.7c-1-2-1.5-4.4-1.5-7.1c0-2.7,0.5-5,1.5-7.1c1-2,2.5-3.6,4.4-4.7
            s4.1-1.7,6.7-1.7c2,0,3.7,0.3,5.3,1c1.6,0.7,2.9,1.6,4,2.8s1.9,2.7,2.5,4.4l-4.9,1.4c-0.5-1.6-1.3-2.8-2.5-3.7s-2.6-1.3-4.4-1.3
            c-1.6,0-3,0.4-4.1,1.1c-1.1,0.7-1.9,1.8-2.5,3.1c-0.6,1.3-0.8,2.9-0.8,4.7c0,1.8,0.3,3.4,0.8,4.7s1.4,2.4,2.5,3.1
            c1.1,0.7,2.5,1.1,4.1,1.1c1.8,0,3.2-0.4,4.4-1.3c1.2-0.9,2-2.1,2.5-3.7l4.9,1.4c-0.6,1.7-1.4,3.2-2.5,4.4c-1.1,1.2-2.4,2.2-4,2.8
            c-1.6,0.7-3.3,1-5.3,1C165.8,63.5,163.6,62.9,161.7,61.8z"/>
            <path d="M189,63h-4.9V37h4.9v10.7h11.6V37h4.9V63h-4.9V52.3H189V63z" />
            <path d="M215.8,63h-4.9V37h4.9V63z" />
            <path d="M234.1,63h-7.3L218.8,37h5l6.5,21.3L237,37h5L234.1,63z" />
            <path d="M261.6,63h-16.9V37h16.9v4.6h-12v5.5h9.9v4.6h-9.9v6.7h12V63z" />
            <path d="M265.9,63V37h8.4c1.3,0,2.3,0,3.1,0.1c2,0.2,3.8,1,5.2,2.1s2.6,2.7,3.4,4.6c0.8,1.9,1.2,3.9,1.2,6.1
            c0,2.2-0.4,4.3-1.2,6.1c-0.8,1.9-1.9,3.4-3.4,4.6c-1.5,1.2-3.2,1.9-5.2,2.1c-0.8,0.1-1.8,0.1-3.1,0.1H265.9z M274.3,58.4
            c1.3,0,2.2-0.1,2.8-0.2c1.1-0.2,2-0.7,2.7-1.5c0.7-0.8,1.3-1.8,1.6-3c0.4-1.2,0.5-2.4,0.5-3.7c0-1.3-0.2-2.6-0.5-3.7
            c-0.4-1.2-0.9-2.1-1.6-2.9c-0.7-0.8-1.6-1.3-2.7-1.5c-0.6-0.1-1.5-0.2-2.8-0.2h-3.4v16.7H274.3z"/>
        </svg>
    )
}